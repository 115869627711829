import {ChatMessageType} from "../slice/chatFeedSlice";


function MessageHandler(){

    const existingMessageIds = new Set<string>()

    const prependMessages = (existingMessages:Array<ChatMessageType>, newMessages: Array<ChatMessageType>):Array<ChatMessageType> =>{
        const validMessages = newMessages.filter(message=>!existingMessageIds.has(message.messageId))
        validMessages.forEach(validMessage=> existingMessageIds.add(validMessage.messageId))
        return [...validMessages, ...existingMessages]
    }

    const appendMessages = (existingMessages:Array<ChatMessageType>, newMessages: Array<ChatMessageType>):Array<ChatMessageType> =>{
        const validMessages = newMessages.filter(message=>!existingMessageIds.has(message.messageId))
        validMessages.forEach(validMessage=> existingMessageIds.add(validMessage.messageId))
        return [...existingMessages, ...validMessages ]
    }

    const appendMessage = (existingMessages:Array<ChatMessageType>,newMessage:ChatMessageType):Array<ChatMessageType>=>{
        existingMessageIds.add(newMessage.messageId)
        return [...existingMessages, newMessage]
    }

    const clearMessages = ():[] =>{
        existingMessageIds.clear()
        return []
    }

    return {
        prependMessages,
        clearMessages,
        appendMessages,
        appendMessage
    }

}

export default MessageHandler();
import React, {useState, UIEvent, useRef, lazy, useLayoutEffect, useEffect} from "react";
import Fab from "@mui/material/Fab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import RichChatView from "./RichChatView";
import { ChatMessageType } from "../app/slice/chatFeedSlice";
import { CircularProgress, Zoom } from "@mui/material";
import TypingIndicator from "./TypingIndicator";
import Loader from "./Loader";
import {User} from "../app/services/auth";

type ChatCanvasProps = {
  avatarURL: string;
  user: User;
  chatFeedMessages: Array<ChatMessageType>;
  pendingResponses: number;
  isDataFetchInProgress:boolean
  fetchData:()=>void
};


function ChatCanvas({
  avatarURL,
  user,
  chatFeedMessages,
  pendingResponses,
  isDataFetchInProgress,
  fetchData, 
}: ChatCanvasProps) {

  const [scrollerVisibility, setScrollerVisibility] = useState(false);

  const chatCanvasRef = useRef<HTMLDivElement | null>(null);

  const [typingIndicator, setTypingIndicator] = useState(false)

  const typingIndicatorTimeoutRef = useRef<NodeJS.Timeout|null>(null)

  useEffect(() => {
        if( pendingResponses > 0 ){
            if(typingIndicatorTimeoutRef.current){
                clearTimeout(typingIndicatorTimeoutRef.current)
            }
            typingIndicatorTimeoutRef.current = setTimeout(()=> setTypingIndicator(false),90000)
            setTypingIndicator(true)
        } else {
            if(typingIndicatorTimeoutRef.current){
                clearTimeout(typingIndicatorTimeoutRef.current)
            }
            setTypingIndicator(false)
        }

    }, [pendingResponses]);



  const handleScroll = (event: UIEvent<HTMLElement>) => {
    if((event.currentTarget.scrollHeight + event.currentTarget.scrollTop - event.currentTarget.clientHeight) <=2){
      fetchData()
    }

    if (event.currentTarget.scrollTop < 0 && !scrollerVisibility) {
      setScrollerVisibility(true);
    } else if (event.currentTarget.scrollTop >= 0) {
      setScrollerVisibility(false);
    }
  };



  const scrollToBottom = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    chatCanvasRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };
  return (
    <Box
      onScroll={handleScroll}
      sx={{
        display: "flex",
        height: "inherit",
        overflowY: "auto",
        overflowAnchor:'none',
        width: "inherit",
        scrollbarWidth: "thin",
        paddingLeft: 1.5,
        paddingTop: 1,
        flexDirection: "column-reverse",
        "&::-webkit-scrollbar": { width: "0.3em" },
        "&::-webkit-scrollbar-track": { opacity: 0 },
        "&::-webkit-scrollbar-thumb": { backgroundColor: "#888" },
        "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
      }}
    >
      {(isDataFetchInProgress && chatFeedMessages.length ===0 ) &&
          <Loader isVisible={true} loaderProps={{height:"20dvh",width:"20dvh"}} opacity={1}/>}
      <div ref={chatCanvasRef} style={{overflowAnchor: "none" }}>
        {
          <>
          {isDataFetchInProgress && chatFeedMessages.length > 0 && 
          <div style={{textAlign:"center"}}><CircularProgress style={{color:"rgb(176 176 176)"}} size={'5vh'} thickness={5}/> </div>}
          
            {chatFeedMessages.map((chatMessage) => {
              return (
                <RichChatView
                  key={chatMessage.messageId}
                  chatMessage={chatMessage}
                  avatarURL={avatarURL}
                  user={user}
                />
              );
            })}
            {typingIndicator && <TypingIndicator avatarURL={avatarURL} />}
          </>
        }
      </div>
      <Zoom in={scrollerVisibility}>
        <Fab
          size="small"
          onClick={scrollToBottom}
          sx={{
            position: "fixed",
            alignSelf: "flex-end",
            marginRight: 2,
            marginBottom: 2,
          }}
        >
          <KeyboardArrowDownIcon />
        </Fab>
      </Zoom>
    </Box>
  );
}

export default ChatCanvas;

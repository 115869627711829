const INSTANCE_ID = "instance_id"

function Global(){

    const setInstanceId = (instanceId: string): void => {
        window.sessionStorage.setItem(INSTANCE_ID, instanceId)
    }

    const getInstanceId = (): string|null => {
        return window.sessionStorage.getItem(INSTANCE_ID) || null
    }

    return {
        setInstanceId,
        getInstanceId,
    }

}


export default Global();



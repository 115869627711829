import socketIOClient, {Socket} from "socket.io-client";
import {ExtensionType} from "../../util/extension.type";
import {CONVOBROKER_SOCKET_URL} from "../config/env";
import {
    ConnectionNameSpace,
    EmitEvent,
    EmitEventResponse,
    EmitEventResponseStatus
} from "../dto";
import {IncomingMessage, OutgoingMessage, processChatResponse} from "../slice/chatFeedSlice";
import {type AppDispatch} from "../store";
import {ConnectionStatus, updateConnectionStatus} from "../slice/baseWidgetSlice";


interface ConversationNSClientToServerEvents {
    [ConnectionNameSpace.CONVERSATION]: (outgoingMessage: OutgoingMessage) => void
}

interface ConversationNSServerToClientEvents {
    [ConnectionNameSpace.CONVERSATION]: (incomingMessage:IncomingMessage) => void;
}

function SocketConnection (){

    let appDispatch:AppDispatch|null = null

    const connectionStatuesMap : Map<string, ConnectionStatus> = new Map();

    const connectionsMap: Map<string, Socket> = new Map<string, Socket>();

    const baseConnection = socketIOClient(CONVOBROKER_SOCKET_URL)

    baseConnection.on('connect_error',(data)=>{
        console.error("Connection failed",data)
    })

    baseConnection.on('connect',()=>{
        console.log("Connection connected")
    })

    baseConnection.on('disconnect',(data)=>{
        console.log("disconnect",data)
    })

    const systemDispatch = ()=>{
        if (appDispatch) {
           return appDispatch
        }
        return (_:any)=>{
            console.error("No dispatch is configured")
        }
    }

    const handleBaseSocketListeners = (connectionNamespace: ConnectionNameSpace, socket: Socket<any,any>)=>{

        socket.on('connect_error',(data)=>{
            console.error(`${connectionNamespace} connection failed`,data)
            updateConnectionStatusMap(connectionNamespace, ConnectionStatus.Disconnected)
        })

        socket.on('connect',()=>{
            updateConnectionStatusMap(connectionNamespace, ConnectionStatus.Connected)
        })

        socket.on('disconnect',(data)=>{
            console.error(`${connectionNamespace} connection disconnected`,data)
            updateConnectionStatusMap(connectionNamespace, ConnectionStatus.Disconnected)
        })

    }

    const updateConnectionStatusMap = (connectionNameSpace: ConnectionNameSpace, updatedConnectionStatus: ConnectionStatus):ConnectionStatus=>{

        connectionStatuesMap.set(connectionNameSpace, updatedConnectionStatus);

        if(updatedConnectionStatus === ConnectionStatus.Disconnected){
          systemDispatch()(updateConnectionStatus(updatedConnectionStatus))
            return updatedConnectionStatus
        }

           const connectionStatus =  processConnectionStatus()
            systemDispatch()(updateConnectionStatus(connectionStatus))
            return connectionStatus
    }

    const processConnectionStatus = ():ConnectionStatus=>{
        const connectionStatuses = Array.from(connectionStatuesMap.values())

        if(connectionStatuses.length === 0){
            return ConnectionStatus.Not_Configured
        }

        if( connectionStatuses.some(cs=>cs === ConnectionStatus.Disconnected)){
            return ConnectionStatus.Disconnected
        }

       if(connectionStatuses.some(cs=>cs === ConnectionStatus.Not_Configured)){
           return ConnectionStatus.Not_Configured
       }

       return ConnectionStatus.Connected
    }

    const conversationNamespace = (token:string, botId:string, extensionType: ExtensionType)=>{
       const conversationConnection:Socket<ConversationNSServerToClientEvents, ConversationNSClientToServerEvents> =
           socketIOClient(`${CONVOBROKER_SOCKET_URL}/${ConnectionNameSpace.CONVERSATION}`, {
            withCredentials: true,
               auth:{
                   token:token,
                   clientType: 'Extension',
                   extensionType:extensionType
               }
        })

        handleBaseSocketListeners(ConnectionNameSpace.CONVERSATION, conversationConnection)

        connectionsMap.set(ConnectionNameSpace.CONVERSATION,conversationConnection)
        conversationConnection.on(ConnectionNameSpace.CONVERSATION,(incomingMessage:IncomingMessage)=>{
            if(incomingMessage.associatedBotId === botId){
                systemDispatch()(processChatResponse(incomingMessage))
            }
        })
    }


    const convoEventNamespace =  (token:string, instanceId: string)=>{
        const convoEventConnection =  socketIOClient(`${CONVOBROKER_SOCKET_URL}/${ConnectionNameSpace.CONVO_EVENT}`, {
            withCredentials: true,
            auth:{
                token:token,
                authType: 'Participant'
            }
        })

        handleBaseSocketListeners(ConnectionNameSpace.CONVO_EVENT, convoEventConnection)

        connectionsMap.set(ConnectionNameSpace.CONVO_EVENT,convoEventConnection)
        convoEventConnection.on(ConnectionNameSpace.CONVO_EVENT,()=>{
            //Todo use instance id to filter valid messages
        })

    }


    const configure = (instanceId:string, token:string, botId:string, extensionType: ExtensionType, dispatch:AppDispatch) => {
        appDispatch = dispatch
        connectionsMap.forEach((connection)=>{
            connection.disconnect()
        })
        connectionsMap.clear()
        connectionStatuesMap.clear()
        conversationNamespace(token,botId,extensionType);
        convoEventNamespace(token,instanceId);
    }

    const emit = async (emitEvent: EmitEvent): Promise<EmitEventResponse> =>{
        const socket = connectionsMap.get(emitEvent.namespace)

        if(!socket){
            return {status: EmitEventResponseStatus.NOT_CONFIGURED}
        }
        const response = await socket.emitWithAck(emitEvent.namespace, emitEvent.event)
        if (response.status === "ok") {
           return {status: EmitEventResponseStatus.SUCCESS}
        }
        if (response.error.message === "Unauthorized") {
                return {status: EmitEventResponseStatus.UNAUTHORIZED}
        }
        return {status: EmitEventResponseStatus.ERROR}
    }


    return {
        configure,
        emit,
    }
}


export default SocketConnection()



import { Avatar, Box, IconButton } from "@mui/material";
import React from "react";

interface ProfileButtonProps {
  setOpen:()=>void;
  userProfileURL:string;
}

function ProfileButton(props: ProfileButtonProps) {

  const handleClick = () => {props.setOpen();};

  return (
    <IconButton
      size="small"
      style={{ borderRadius: 50, background: "rgb(38 40 46)" }}
      sx={{
        height: 30,
      }}
      onClick={handleClick}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
    
        }}
      >
        <Avatar
          sx={{
            width: "auto",
            height: 20,
          }}
          style={{ aspectRatio: 1 }}
          alt="profileAvatarURL"
          src={props.userProfileURL}
        />
      </Box>
    </IconButton>
  );
}

export default ProfileButton;


import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export enum CanvasComponentKey {
    CHAT = 'chat'
}

export interface NavigationState {
    liveComponent: CanvasComponentKey;
}

const initialState: NavigationState = {
    liveComponent: CanvasComponentKey.CHAT,
};

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState,
    reducers: {
      navigate: (state, action: PayloadAction<CanvasComponentKey>) => {
        state.liveComponent  = action.payload
      },
    },
    extraReducers: (builder) => {builder.addDefaultCase(() => {})}
})

export const { navigate } = navigationSlice.actions;

export const selectLiveComponent = (state: RootState) => state.navigation.liveComponent;

export default navigationSlice.reducer;

import {Button, Typography} from "@mui/material";

// Implement the exchange and save the user and restart
interface SignInButtonProps {
    onClick:()=>void;
}
function SignInButton(props: SignInButtonProps) {
  return (
    <Button
      variant="contained"
      style={{ borderRadius: 23, background: "rgb(38 40 46)" }}
      sx={{
        height: 30,
        width: 100,
        mx: 1,
      }}
      onClick={()=>{
          props.onClick()
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: 10,
          fontWeight: 600,
        }}
      >
        Sign In
      </Typography>
    </Button>
  );
}

export default SignInButton;

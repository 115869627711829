import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {v4 as uuidv4} from "uuid";
import {RootState} from "../store";
import {axiosInstance} from "../services/axios";
import {BaseWidgetSuccessState, refetchIdentity} from "./baseWidgetSlice";
import {ConnectionNameSpace, EmitEventResponse, EmitEventResponseStatus} from "../dto";
import SocketConnection from '../services/socket'
import MessageHandler from '../services/message'
import Global from "../services/global";

export interface ChatMessageType {
  type: string;
  messageId: string;
}

export type Source = {
  title: string;
  source: string;
};

export type OutgoingMessage = {
  messageId: string
  from: string,
  to: string,
  associatedBotId: string,
  channel: string,
  receiverType: string,
  instanceId: string|null
  content: {
    text: string
  },
}

export type IncomingMessage = {
  messageId: string
  from: string,
  to: string,
  associatedBotId: string,
  createdAt: Date,
  channel: string,
  receiverType: string,
  sources: Array<Source>,
  status: IncomingMessageStatus
  content: {
    text: string,
  },
}



export type PreviousMessageFetchResponse = {
  isFetchComplete: boolean
  previousNextPageMarker: string|null;
  nextPageMarker: string|null;
  messages: Array<ChatMessageType>;
};

export enum ChatType {
  REQUEST = 'request',
  RESPONSE = 'response'
}

export enum IncomingMessageStatus {
  Success = "success",
  Error = "error"
}


export enum OutgoingMessageStatus {
  Pending = "pending",
  Success = "success",
  Error = "error"
}



export interface ChatResponse extends ChatMessageType {
  type: ChatType.RESPONSE;
  messageId: string;
  text: string;
  sources: Array<Source>;
  status: IncomingMessageStatus
}

export interface ChatRequest extends ChatMessageType {
  messageId: string;
  type: ChatType.REQUEST;
  text: string;
}

export function createChatRequest(message: string): ChatRequest {
  return {
    messageId: uuidv4(),
    type: ChatType.REQUEST,
    text: message,
  };
}

export function isRequest(
  chatMessage: ChatMessageType
): chatMessage is ChatRequest {
  return chatMessage.type === "request";
}

export function isResponse(
  chatMessage: ChatMessageType
): chatMessage is ChatResponse {
  return chatMessage.type === "response";
}

export type ChatRequestEnvelop = {
  recipientId: string;
  botId: string;
  userId: string;
  chatRequest: ChatRequest;
};

// type ChatResponseEnvelop = {
//   role: string;
//   text: string;
//   sources: Array<Source>;
//   userId: string;
// };

export type ChatFeedState = {
  pendingResponses: number;
  messages: Array<ChatMessageType>;
  isFetchComplete: boolean;
  fetchPreviousMessagesLoading: boolean;
  nextPageMarker: string | null;
  fetchPreviousMessagesError: string | null;
};

const initialState: ChatFeedState = {
  pendingResponses: 0,
  messages: [],
  isFetchComplete: false,
  fetchPreviousMessagesLoading: false,
  fetchPreviousMessagesError: null,
  nextPageMarker:null,
};

export const processPreviousMessages = createAsyncThunk(
  "chatFeed/processPreviousMessages",
  async (_,{ getState }): Promise<PreviousMessageFetchResponse> => {
    const { baseWidget, chatFeed } = getState() as { baseWidget: BaseWidgetSuccessState,chatFeed: ChatFeedState };

    const currentParticipant = baseWidget.identity.participantId
    const botId = baseWidget.botId
    const botRecipientId  = baseWidget.isDraft ? baseWidget.versionId : baseWidget.botId
    const paginationQuery = chatFeed.nextPageMarker ? `&nextPageMarker=${chatFeed.nextPageMarker}`: ""
    const response = await axiosInstance.get(
      `/conversationParties/${currentParticipant}/messages?with=${botRecipientId}&associatedBotId=${botId}&direction=older${paginationQuery}`,
      { headers: { "x-chatId": baseWidget.identity.participantToken } }
      );

    if (!response || !response.data) {
      throw new Error("Messages not found");
    }

    const chatMessages = response.data.items as Array<ChatMessageType>;

    const nextPageMarker = response.data.nextPageMarker as string|null;

    const messages: Array<ChatMessageType> = chatMessages.map((message: any) => {
      if (message.from === currentParticipant) {
        return {
          type: "request",
          messageId: message.messageId,
          text: message.content.text,
        };
      } else {
        return {
          type: "response",
          messageId: message.messageId,
          text: message.content.text,
          sources: message.sources,
        };
      }
    });

    return {
      isFetchComplete:!nextPageMarker,
      previousNextPageMarker:chatFeed.nextPageMarker, 
      nextPageMarker, 
      messages};
  }
);

export const processChatResponse = createAsyncThunk(
  "chatFeed/processChatResponse",
  async (incomingMessage: IncomingMessage): Promise<ChatResponse> => {
    return {
      type: ChatType.RESPONSE,
      messageId:incomingMessage.messageId,
      text: incomingMessage.content.text,
      sources: incomingMessage.sources,
      status: incomingMessage.status
    };
  }
);



export const processChatRequest = createAsyncThunk(
  "chatFeed/chat",
  async (
    chatRequestEnvelop: ChatRequestEnvelop,
    { dispatch, getState }
  ): Promise<EmitEventResponse> => {
    const { baseWidget } = getState() as { baseWidget: BaseWidgetSuccessState};
    const sendMessage = {
      messageId: chatRequestEnvelop.chatRequest.messageId,
      from: chatRequestEnvelop.userId,
      to: chatRequestEnvelop.recipientId,
      associatedBotId: chatRequestEnvelop.botId,
      channel: baseWidget.extensionType,
      receiverType: "Bot",
      instanceId: Global.getInstanceId(),
      content: {
        text: chatRequestEnvelop.chatRequest.text,
      },
    };

    return await SocketConnection.emit({
     namespace:ConnectionNameSpace.CONVERSATION,
     event:sendMessage
    })
  }
);

export const chatFeedSlice = createSlice({
  name: "chatFeed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(processChatRequest.pending, (state, action) => {
        state.messages = MessageHandler.appendMessage(state.messages, action.meta.arg.chatRequest)
        state.pendingResponses = state.pendingResponses + 1;
      })
      .addCase(processChatRequest.fulfilled, (state, action) => {
        if(action.payload.status !== EmitEventResponseStatus.SUCCESS){
          // There is no way this goes below zero, but just using defensive programming here.
          state.pendingResponses = Math.max(0, state.pendingResponses - 1);
        }
      })
      .addCase(processChatResponse.fulfilled, (state, action) => {
        // There is no way this goes below zero, but just using defensive programming here.
        state.pendingResponses = Math.max(0, state.pendingResponses - 1);
        state.messages = MessageHandler.appendMessage(state.messages, action.payload)
      })
      .addCase(processPreviousMessages.pending, (state) => {
        state.fetchPreviousMessagesLoading = true;
      })
      .addCase(processPreviousMessages.fulfilled, (state, action) => {

        if(action.payload.previousNextPageMarker ===  state.nextPageMarker){
          state.fetchPreviousMessagesLoading = false;
          state.messages = MessageHandler.prependMessages(state.messages,action.payload.messages )
          state.isFetchComplete = action.payload.isFetchComplete
          state.nextPageMarker = action.payload.nextPageMarker;
        }
      
      })
      .addCase(refetchIdentity.pending, (state, action) => {
          state.pendingResponses = 0;
          state.messages = MessageHandler.clearMessages()
        })
      .addCase(processPreviousMessages.rejected, (state, action) => {
        state.fetchPreviousMessagesLoading = false;
        state.fetchPreviousMessagesError = "Error fetching previous messages";
      })
      .addDefaultCase(() => {});
  },
});


export const selectChatFeedMessages = (state: RootState) => state.chatFeed.messages;

export const selectChatFeedPendingResponsesCount = (state: RootState) => state.chatFeed.pendingResponses;

const selectFetchPreviousMessagesError = (state: RootState) => state.chatFeed.fetchPreviousMessagesError
const selectIsFetchComplete = (state: RootState) => state.chatFeed.isFetchComplete
const selectFetchPreviousMessagesLoading = (state: RootState) => state.chatFeed.fetchPreviousMessagesLoading

export const selectChatStatus= createSelector(
    [selectFetchPreviousMessagesError, selectIsFetchComplete, selectFetchPreviousMessagesLoading],
    (messageFetchError , isAllDataFetched, isFetchInProgress) => ({
      isError:messageFetchError !== null,
      isAllDataFetched,
      isFetchInProgress,
    }))


export default chatFeedSlice.reducer;

import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import navigationReducer, { NavigationState } from "./slice/navigationSlice";
import baseWidgetReducer, { BaseWidgetState } from "./slice/baseWidgetSlice";
import chatFeedReducer, { ChatFeedState } from "./slice/chatFeedSlice";

export function configStore() {
  return configureStore({
    reducer: {
      navigation: navigationReducer,
      baseWidget: baseWidgetReducer,
      chatFeed: chatFeedReducer,
    },
  });
}

export type AppDispatch = ThunkDispatch<
  {
    navigation: NavigationState;
    baseWidget: BaseWidgetState;
    chatFeed: ChatFeedState;
  },
  undefined,
  AnyAction
>;

export type RootState = {
  navigation: NavigationState;
  baseWidget: BaseWidgetState;
  chatFeed: ChatFeedState;
};
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const store = configStore();

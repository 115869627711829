import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {Avatar, IconButton, Paper, Typography} from "@mui/material";
import ChatTextBox from "../component/ChatTextBox";
import ChatCanvas from "../component/ChatCanvas";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    createChatRequest,
    processChatRequest,
    processPreviousMessages,
    selectChatFeedMessages,
    selectChatFeedPendingResponsesCount,
    selectChatStatus,
} from "../app/slice/chatFeedSlice";
import SignInButton from "../component/SignInButton";
import ProfileButton from "../component/ProfileButton";
import Profile from "../component/Profile";
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';
import CloudOffTwoToneIcon from '@mui/icons-material/CloudOffTwoTone';
import {BaseWidgetSuccessState, ConnectionStatus, refetchIdentity} from "../app/slice/baseWidgetSlice";
import {UserType} from "../app/services/auth";
import {CONVOGRID_LOGIN_URL} from "../app/config/env";


function Chat({baseWidgetData, close}: { baseWidgetData: BaseWidgetSuccessState, close: () => void }) {
    const [isProfileOpen, setIsProfileOpen] = React.useState(false);
    const chatFeedMessages = useAppSelector(selectChatFeedMessages);
    const pendingResponses = useAppSelector(selectChatFeedPendingResponsesCount);
    const chatStatus = useAppSelector(selectChatStatus);

    const dispatch = useAppDispatch();

    const dispatchChatRequest = (message: string) => {
        dispatch(
            processChatRequest({
                botId: baseWidgetData.botId,
                recipientId: baseWidgetData.isDraft ? baseWidgetData.versionId : baseWidgetData.botId,
                userId: baseWidgetData.identity.participantId,
                chatRequest: createChatRequest(message),
            })
        );
    };

    useEffect(() => {
        if(baseWidgetData.isReady === true){
            dispatch(processPreviousMessages());
        }
    }, [dispatch,baseWidgetData.isReady]);


    const signIn = ()=>{
        dispatch(refetchIdentity({delegationUrl:CONVOGRID_LOGIN_URL}))
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "end",
                height: "inherit",
                width: "inherit",
            }}
        >
            <Paper
                elevation={0}
                square={true}
                style={{
                    backgroundColor: "transparent",
                    height: "fit-content",
                    paddingTop: 7,
                    paddingBottom: 7,
                    paddingLeft: 7,
                    borderBottom: "0.01rem solid #eeeeee",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <Avatar
                                sx={{width: 30, height: 30}}
                                src={baseWidgetData.botAvatarURL}
                                style={{border: "solid rgba(0,0,0,0.15)", borderWidth: "1px"}}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" style={{fontWeight: 600}}>
                                {baseWidgetData.botName}
                            </Typography>
                        </Grid>
                        {(chatStatus.isError || baseWidgetData.connectionStatus === ConnectionStatus.Disconnected)  &&
                            <Grid item>
                                <CloudOffTwoToneIcon style={{color: "red"}}/>
                            </Grid>
                        }
                    </Grid>

                    {baseWidgetData.identity.userType === UserType.ConvoDelegatedUser &&
                        (
                            <ProfileButton
                                userProfileURL={baseWidgetData.identity.user.profileUri}
                                setOpen={() => setIsProfileOpen(true)}/>
                        )
                    }

                    {baseWidgetData.identity.userType === UserType.AnonymousUser && (<SignInButton onClick={signIn}/>)}

                    <IconButton onClick={close} color="primary"> <ExpandCircleDownRoundedIcon/></IconButton>

                </Box>
            </Paper>
            <Profile
                open={isProfileOpen}
                extensionType={baseWidgetData.extensionType}
                identity={baseWidgetData.identity}
                urlPath={baseWidgetData.urlPath}
                onClose={()=>setIsProfileOpen(false)}
            />
            <ChatCanvas
                chatFeedMessages={chatFeedMessages}
                pendingResponses={pendingResponses}
                avatarURL={baseWidgetData.botAvatarURL}
                user={baseWidgetData.identity.user}
                isDataFetchInProgress={chatStatus.isFetchInProgress}
                fetchData={() => {
                    if (!chatStatus.isAllDataFetched && !chatStatus.isFetchInProgress) {
                        dispatch(processPreviousMessages())
                    }
                }}
            />

            <Paper
                elevation={0}
                square={true}
                style={{
                    backgroundColor: "transparent",
                    width: "inherit",
                    paddingTop: 10,
                    alignItems: "center",
                    borderTop: "0.01rem solid #eeeeee",
                }}
            >
                <Container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 20,
                    }}
                >
                    <ChatTextBox
                        sendHandler={dispatchChatRequest}
                        isChatReady={!(chatStatus.isFetchInProgress && chatFeedMessages.length === 0)}
                    />
                </Container>
                <Container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 7,
                    }}
                >
          <span
              style={{
                  fontSize: "min(max(4vh,6px),10px)",
                  color: "rgba(142,142,142,1)",
                  fontFamily: "Roboto",
              }}
          >
            Powered By{" "}
              <span style={{color: "blue"}}>
              <b style={{fontFamily: "Nunito,Sans", fontWeight: "900"}}>
                ConvoGrid
              </b>
            </span>{" "}
          </span>
                </Container>
            </Paper>
        </Box>
    );
}

export default Chat;

import { useState } from "react";
import { Button, InputAdornment, TextField } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';


interface ChatTextBoxPros{
    sendHandler: (message: string)=>void,
    isChatReady: boolean
}

function ChatTextBox({sendHandler, isChatReady}:ChatTextBoxPros) {

    const [content, setContent] = useState("");

    return (
        <TextField
            onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                    if(content.trim()!==""){
                        ev.preventDefault();
                        const message = content
                        setContent("")
                        sendHandler(message)
                    }
                }
            }}
            InputProps={{ 
                sx: { 
                    borderRadius: 2, 
                    border:'0.1rem solid rgba(209,209,209,1)', 
                    background:'rgba(246,246,246,1)',
                    paddingRight:0.4,
                    fontSize:'0.9em',
                },
                endAdornment:  
                        <InputAdornment position="end">
                            <Button 
                                variant="contained" 
                                size='small' 
                                disableElevation 
                                disabled={(content.trim() ==="") || !isChatReady}
                                onClick={()=>{
                                    const message = content
                                        setContent("")
                                        sendHandler(message)
                                }}
                                style={{
                                        borderRadius:5, 
                                        minHeight: 0, 
                                        minWidth: 0, 
                                        padding: 6
                                }}>
                                <SendIcon fontSize="small" />
                            </Button>
                        </InputAdornment> 
                    }}
            fullWidth
            multiline
            maxRows={3}
            value={content} 
            onChange={event=>setContent(event.target.value)}
            placeholder = {"Type a message"}
            size="small" 
            style={{width:'inherit', minWidth:'250px'}}/>
    )

}

export default ChatTextBox
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {Avatar} from "@mui/material";
import "./css/helper.css";

type TypingIndicatorProps = {
  avatarURL: string;
};
function TypingIndicator({ avatarURL }: TypingIndicatorProps) {

  return <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        width: "70%",
        pt: 1,
        pb: 1,
      }}
    >
      <Avatar
        sx={{ width: 20, height: 20 }}
        src={avatarURL}
        style={{ border: "solid rgba(0,0,0,0.15)", borderWidth: "1px" }}
      />
      <Card
        variant="outlined"
        sx={{ ml: 1 }}
        style={{
          background: "rgba(246,246,246,1)",
          border: "none",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
          borderBottomLeftRadius: 0,
        }}
      >
        <CardContent
          style={{
            fontSize: "0.9em",
            padding: 10,
            fontWeight: 500,
            wordWrap: "break-word",
          }}
        >
          <svg height="15" width="40">
            <circle
              className="dot"
              cx="10"
              cy="10"
              r="3"
              style={{ fill: "grey" }}
            />
            <circle
              className="dot"
              cx="20"
              cy="10"
              r="3"
              style={{ fill: "grey" }}
            />
            <circle
              className="dot"
              cx="30"
              cy="10"
              r="3"
              style={{ fill: "grey" }}
            />
          </svg>
        </CardContent>
      </Card>
    </Box>

}

export default TypingIndicator;


import {axiosInstance} from "./axios";
import {BrowserMessageEventType} from "../dto";
import {CONVOGRID_ORIGIN} from "../config/env";
export enum UserType {
    ConvoUser = "ConvoUser",
    ConvoDelegatedUser = "ConvoDelegatedUser",
    AnonymousUser = "AnonymousUser"
}

const IDENTITY = "identity"
const ANONYMOUS_IDENTITY = "anonymousIdentity"

export type Identity  = {
    participantToken:string,
    participantId: string,
    userType: UserType,
    allowPrivateExt:boolean
    user:User
}


type ParticipantResponse = {
    participantId:string,
    conversationPartyIdentifier: string
}

type UserUpdateProps = {
    allowPrivateExt:boolean
}


export type User = {
    id: string;
    displayName: string;
    profileUri: string;
};


function Auth(){

    const clearUser = ()=>{
        window.sessionStorage.removeItem(IDENTITY);
        window.localStorage.removeItem(IDENTITY);
    }

    const setUser = (userType: UserType, user:User, participantToken: string, participantId: string, allowPrivateExt= false):Identity=>{

        const identity :Identity = {
            participantToken: participantToken,
            participantId: participantId,
            userType: userType,
            allowPrivateExt:allowPrivateExt,
            user: user
        }

        switch (userType){
            case UserType.ConvoUser:
                window.sessionStorage.setItem(IDENTITY, JSON.stringify(identity));
                break;
            case UserType.ConvoDelegatedUser:
                window.localStorage.setItem(IDENTITY, JSON.stringify(identity));
                break;
            case UserType.AnonymousUser:
                window.localStorage.setItem(ANONYMOUS_IDENTITY, JSON.stringify(identity));
                break;
        }

        return identity
    }

    const setAnonymousUser = async ():Promise<Identity> => {

       const participantResponse =  await axiosInstance.get<ParticipantResponse>("/participants/me");

        const anonymousUser = {
            id: participantResponse.data.participantId,
            displayName:"Anonymous User",
            profileUri: "https://convogridpublicassets.blob.core.windows.net/user/anonymous.jpg",
        }

        setUser(UserType.AnonymousUser, anonymousUser, participantResponse.data.conversationPartyIdentifier, participantResponse.data.participantId)
        return {
            participantToken: participantResponse.data.conversationPartyIdentifier,
            participantId: participantResponse.data.participantId,
            userType: UserType.AnonymousUser,
            allowPrivateExt:false,
            user:anonymousUser
        }
    }

    const updateUser = (userUpdateProps:UserUpdateProps):Identity|null=>{
        const sessionIdentity = window.sessionStorage.getItem(IDENTITY);
        if(sessionIdentity){
            const updatedIdentity = {
                ...JSON.parse(sessionIdentity),
                ...userUpdateProps
            }
            window.sessionStorage.setItem(IDENTITY, JSON.stringify(updatedIdentity));
            return updatedIdentity
        }

        const permanentIdentity = window.localStorage.getItem(IDENTITY);
        if(permanentIdentity){
            const updatedIdentity = {
                ...JSON.parse(permanentIdentity),
                ...userUpdateProps
            }
            window.localStorage.setItem(IDENTITY, JSON.stringify(updatedIdentity));
            return updatedIdentity
        }

        const anonymousIdentity = window.localStorage.getItem(ANONYMOUS_IDENTITY);
        if(anonymousIdentity){
            const updatedIdentity = {
                ...JSON.parse(anonymousIdentity),
                ...userUpdateProps
            }
            window.localStorage.setItem(ANONYMOUS_IDENTITY, JSON.stringify(updatedIdentity));
            return updatedIdentity
        }

        return null
    }

    const getUser = (): Identity|null=>{

      const sessionIdentity = window.sessionStorage.getItem(IDENTITY);
      if(sessionIdentity){
          return JSON.parse(sessionIdentity);
      }

      const permanentIdentity = window.localStorage.getItem(IDENTITY);
      if(permanentIdentity){
          return JSON.parse(permanentIdentity);
      }

      const anonymousIdentity = window.localStorage.getItem(ANONYMOUS_IDENTITY);
      if(anonymousIdentity){
          return JSON.parse(anonymousIdentity);
      }

      return null

    }


    const signInWithDelegation = (delegationUrl:string): Promise<Identity|null> => {
        return new Promise(resolve => {
            const controller = new AbortController;
            let childWindow: Window | null = null
            window.addEventListener("message", (event: any) => {
                if (event.data.type === BrowserMessageEventType.CONVO_USER_RESP) {
                    if (event.origin === CONVOGRID_ORIGIN) {
                        const identity = setUser(UserType.ConvoDelegatedUser, event.data.user, event.data.participantToken, event.data.participantId)
                        childWindow?.close();
                        controller.abort()
                        resolve(identity)
                        return
                    }
                }
            },{signal: controller.signal});
            childWindow = window.open(delegationUrl, "LoginPopup");
        })
    }

    return {
        signInWithDelegation,
        setAnonymousUser,
        getUser,
        setUser,
        clearUser,
        updateUser
    }

}


export default Auth();
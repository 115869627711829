import { FC } from 'react';
import { Backdrop, Box } from "@mui/material";

export interface SvgIconConstituentValues {
    fill?: string;
    strokeColor?: string;
    strokeWidth?: string;
    strokeWidth2?: string;
    strokeWidth3?: string;
    strokeFill?: string;
    fillColor?: string;
    fillColor2?: string;
    fillColor3?: string;
    fillColor4?: string;
    fillColor5?: string;
    fillColor6?: string;
    fillColor7?: string;
    imageWidth?: string;
    imageHeight?: string;
    width?: string;
    height?: string;
    rotateCenter?: number;
    className?: string;
    className2?: string;
    className3?: string;
    className4?: string;
    className5?: string;
  }
  

export const TabletLogoIcon: FC<SvgIconConstituentValues> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="43"
    viewBox="0 0 55 43"
    fill="none"
    {...props}
  >
    <path
      d="M0.563992 9.13067C0.563992 4.39943 4.39942 0.563992 9.13066 0.563992H41.5722C47.3932 0.563992 51.5192 6.24466 49.7189 11.7802L44.6524 27.3584C43.5049 30.8869 40.2162 33.2755 36.5058 33.2755H0.563992V9.13067Z"
      fill="#73A4FF"
      stroke="white"
      strokeWidth="1.12798"
    />
    <path
      d="M0.563992 15.7322C0.563992 11.001 4.39942 7.16555 9.13066 7.16555H34.188C39.2947 7.16555 42.8373 12.2556 41.0634 17.0443L37.0598 27.852C35.815 31.2124 32.6102 33.4429 29.0266 33.4429H0.563992V15.7322Z"
      fill="#3A80FF"
      stroke="white"
      strokeWidth="1.12798"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.973 13.4609C4.19095 13.4609 1.125 16.5269 1.125 20.3089V32.8769H1.14062V39.9784C1.14062 42.2278 4.07199 43.0918 5.29193 41.202L10.6662 32.8769H20.7376C23.4021 32.8769 25.8246 31.3314 26.9476 28.9152L30.4042 21.4779C32.1417 17.7395 29.4121 13.4609 25.2897 13.4609H7.973Z"
      fill="#025BFE"
    />
    <path
      d="M1.125 32.8769H0.561008V33.4409H1.125V32.8769ZM1.14062 32.8769H1.70462V32.3129H1.14062V32.8769ZM5.29193 41.202L5.76577 41.5079H5.76577L5.29193 41.202ZM10.6662 32.8769V32.3129H10.359L10.1924 32.5711L10.6662 32.8769ZM26.9476 28.9152L26.4362 28.6775L26.9476 28.9152ZM30.4042 21.4779L30.9157 21.7156L30.4042 21.4779ZM1.68899 20.3089C1.68899 16.8384 4.50244 14.0249 7.973 14.0249V12.8969C3.87947 12.8969 0.561008 16.2154 0.561008 20.3089H1.68899ZM1.68899 32.8769V20.3089H0.561008V32.8769H1.68899ZM1.14062 32.3129H1.125V33.4409H1.14062V32.3129ZM1.70462 39.9784V32.8769H0.576633V39.9784H1.70462ZM4.8181 40.8961C3.90314 42.3134 1.70462 41.6654 1.70462 39.9784H0.576633C0.576633 42.7901 4.24084 43.8701 5.76577 41.5079L4.8181 40.8961ZM10.1924 32.5711L4.8181 40.8961L5.76577 41.5079L11.14 33.1828L10.1924 32.5711ZM20.7376 32.3129H10.6662V33.4409H20.7376V32.3129ZM26.4362 28.6775C25.4057 30.8947 23.1826 32.3129 20.7376 32.3129V33.4409C23.6215 33.4409 26.2436 31.7681 27.4591 29.1529L26.4362 28.6775ZM29.8927 21.2402L26.4362 28.6775L27.4591 29.1529L30.9157 21.7156L29.8927 21.2402ZM25.2897 14.0249C28.9999 14.0249 31.4565 17.8756 29.8927 21.2402L30.9157 21.7156C32.8269 17.6034 29.8244 12.8969 25.2897 12.8969V14.0249ZM7.973 14.0249H25.2897V12.8969H7.973V14.0249Z"
      fill="white"
    />
    <path
      d="M1.125 13.5381C1.125 16.3581 1.68899 15.7941 3.94496 14.1021C2.81698 15.2302 1.68899 16.7341 1.125 18.0501V13.5381Z"
      fill="white"
    />
    <path
      d="M1.125 7.39751C1.125 10.2175 1.68899 9.65348 3.94496 7.9615C2.81698 9.08961 1.68899 10.5935 1.125 11.9094V7.39751Z"
      fill="white"
    />
    <path
      d="M28.764 32.7136C24.2521 32.7136 26.5081 31.5856 27.636 28.7656C26.5079 31.5856 19.7403 33.2776 21.9962 33.2776C24.2522 33.2776 33.276 32.7136 28.764 32.7136Z"
      fill="white"
    />
    <path
      d="M37.6756 32.7136C33.1637 32.7136 36.0906 31.5856 37.2186 28.7656C36.0905 31.5856 28.6519 33.2776 30.9079 33.2776C33.1638 33.2776 42.1876 32.7136 37.6756 32.7136Z"
      fill="white"
    />
  </svg>
);


type LoaderProps ={
    height: string
    width: string
}

type Props = {
    isVisible: boolean
    loaderProps:LoaderProps
    opacity: number
};
  
  const Loader = ({loaderProps}: Props) => {
    return (
        <Box display="flex" margin={"auto"}  justifyContent="center" alignItems="center"flexDirection={"column"} sx={{  opacity:"1 !important",}}>
        <Box sx={{ 
          zIndex:1000,
          opacity:"1 !important",
          animation: 'scale 1s linear infinite alternate',
                    '@keyframes scale': {
                      from: {
                        transform: 'scale(0.7)'
                      },
                      to: {
                        transform: 'scale(1.0)'
                      }
                    }
                  }}
          >
          <TabletLogoIcon {...loaderProps }  />
          </Box>
        </Box>
    );
  };
  
  export default Loader;
import SocketConnection from './socket'
import {ChatEventType, ConnectionNameSpace, ConvoEventData, ConvoEventType} from "../dto";


function EventHandler(){

    const handle = async (convoEvent:ConvoEventData)=>{
        switch (convoEvent.type){
            case ConvoEventType.CHAT_EVENT : {
             switch (convoEvent.chatEventType) {
                 case ChatEventType.CHAT_LOADED:{
                     await SocketConnection.emit({
                         namespace: ConnectionNameSpace.CONVO_EVENT,
                         event: convoEvent
                     })
                     break;
                 }
             }
             break;
            }

        }
    }

    return {
        handle
    }

}

export default EventHandler();
import {ActionReducerMapBuilder, createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {axiosInstance} from "../services/axios";
import {ExtensionType} from "../../util/extension.type";
import Auth, {Identity} from "../services/auth";


export enum ConnectionStatus{
    Not_Configured = "Not_Configured",
    Connected = "Connected",
    Disconnected = "Disconnected"
}
export type LoadBaseWidgetDataRequest = {
  urlPath: string;
  extensionType: ExtensionType;
};

export type DelegationWebRequest = {
    delegationUrl: string;
};

export type BaseWidgetSuccessState = {
  botId: string;
  isDraft: boolean;
  versionId: string;
  botName: string;
  botAvatarURL: string;
  description: string;
  extensionType: ExtensionType;
  urlPath:string
  isReady: boolean|null;
  identity: Identity;
  connectionStatus: ConnectionStatus;
} ;

export type BaseWidgetFailedState = { connectionStatus: ConnectionStatus, isReady: false,  urlPath:string };

export type BaseWidgetState = BaseWidgetSuccessState | BaseWidgetFailedState

const initialState: BaseWidgetState = {
    connectionStatus: ConnectionStatus.Not_Configured,
    isReady: false,
    urlPath: ""
};

type BotMetaDataResponse = {
    isDraft: boolean;
    extensionType: ExtensionType;
    versionId:string;
    botName: string;
    botAvatarURL: string;
    botId: string;
    description: string;
    identity: Identity;
};


export const refetchIdentity = createAsyncThunk(
    "baseWidget/refetchIdentity",
    async (request?: DelegationWebRequest): Promise<Identity|null> => {
        Auth.clearUser()
        if(request){
            return await Auth.signInWithDelegation(request.delegationUrl)
        }

        return Auth.getUser() || await Auth.setAnonymousUser()
    }
)

export const loadBaseWidgetData = createAsyncThunk(
  "baseWidget/fetchData",
  async (request: LoadBaseWidgetDataRequest): Promise<BotMetaDataResponse|null> => {

      const user = Auth.getUser() || await Auth.setAnonymousUser()


      // If user has opted for private extensions then we first try to grab those
      if(user.allowPrivateExt) {

          const privateBots = await axiosInstance.get(
              `/extensions/${ExtensionType.PrivateExtension}/bots?identifier=${encodeURIComponent(request.urlPath)}`,
              {headers: {"x-chatId": user.participantToken}}
          );

          const privateBot = privateBots?.data?.items?.pop()
          const liveVersion = privateBot?.liveVersion
          const draftVersion = privateBot?.draftVersions.pop()
          const bot = liveVersion || draftVersion

          if(bot){
              return {
                  isDraft:!liveVersion,
                  extensionType: ExtensionType.PrivateExtension,
                  botName: bot.name,
                  botAvatarURL: bot.profileUrl,
                  botId: bot.botId,
                  versionId:bot.versionId,
                  description: bot.description,
                  identity: user,
              };
          }

      }


      const requestURLPath = request.extensionType === ExtensionType.CommercialExtension ? new URL(request.urlPath).origin :  request.urlPath

      //If user has not opted for private extensions then we try to find a specific bot based on the channel url.
      const specifiedBots = await axiosInstance.get(`/extensions/${request.extensionType}/bots?identifier=${encodeURIComponent(requestURLPath)}`);
      const specificBot = specifiedBots?.data?.items?.pop()?.liveVersion;
      if(specificBot){
          return {
              isDraft:false,
              extensionType: request.extensionType,
              botName: specificBot.name,
              botAvatarURL: specificBot.profileUrl,
              botId: specificBot.botId,
              versionId:specificBot.versionId,
              description: specificBot.description,
              identity: user,
          };
      }

      //If there is no specific bot for the url we try to find a default bot.
      const defaultBots = await axiosInstance.get(`/extensions/${ExtensionType.CommercialExtension}/bots?identifier=${encodeURIComponent(`https://youtube.com`)}`);
      const defaultBot = defaultBots?.data?.items?.pop()?.liveVersion;
      if(defaultBot){
          return {
              isDraft:false,
              extensionType: ExtensionType.CommercialExtension,
              botName: defaultBot.name,
              botAvatarURL: defaultBot.profileUrl,
              botId: defaultBot.botId,
              versionId:defaultBot.versionId,
              description: defaultBot.description,
              identity: user,
          };
      }

      // If none of the above options worked we just give up :(
      return null
  }
);

export const baseWidgetSlice = createSlice({
  name: "baseWidget",
  initialState: initialState as BaseWidgetState,
  reducers: {
        updateConnectionStatus: (state, action: PayloadAction<ConnectionStatus>) => {
            state.connectionStatus  = action.payload
        },
    },
  extraReducers: (builder:ActionReducerMapBuilder<BaseWidgetState>) => {
    builder
        .addCase(loadBaseWidgetData.pending,  (state, action) => {
           state.urlPath = action.meta.arg.urlPath
        })
      .addCase(
        loadBaseWidgetData.fulfilled,
        (state  , action: PayloadAction<BotMetaDataResponse|null>)=> {
            if(action.payload) {
                return {
                    isReady: true,
                    isDraft: action.payload.isDraft,
                    botId: action.payload.botId,
                    versionId: action.payload.versionId,
                    botName: action.payload.botName,
                    botAvatarURL: action.payload.botAvatarURL,
                    description: action.payload.description,
                    identity: action.payload.identity,
                    extensionType: action.payload.extensionType,
                    connectionStatus: state.connectionStatus,
                    urlPath:state.urlPath
                }
            }
            return {
                isReady: false,
                connectionStatus: state.connectionStatus,
                urlPath:state.urlPath
            }
        }
      )
      .addCase(loadBaseWidgetData.rejected, (state) => {
          return {
              isReady:false,
              connectionStatus: state.connectionStatus,
              urlPath:state.urlPath
          }
      })
       .addCase(refetchIdentity.pending, (state) => {
           state.isReady = null
        })
        .addCase(
            refetchIdentity.fulfilled,
            (state  , action: PayloadAction<Identity|null>)=> {
                if(action.payload && state.isReady === null) {
                    return {
                        ...state,
                        isReady: true,
                        identity: action.payload
                    }
                }
                return {
                    isReady: false,
                    connectionStatus: state.connectionStatus,
                    urlPath:state.urlPath
                }
            }
        )
        .addCase(refetchIdentity.rejected, (state) => {
            return {
                isReady:false,
                connectionStatus: state.connectionStatus,
                urlPath:state.urlPath
            }
        })

      .addDefaultCase(() => {});
  },
});


export const { updateConnectionStatus } = baseWidgetSlice.actions;

export const selectBaseWidgetData = (state: RootState) => state.baseWidget;

export default baseWidgetSlice.reducer;

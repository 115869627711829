import { ReactElement } from "react";
import { useAppSelector } from "./app/hooks";
import {CanvasComponentKey, selectLiveComponent} from "./app/slice/navigationSlice";
import Chat from "./container/Chat";
import { Container } from "@mui/material";
import {BaseWidgetSuccessState, selectBaseWidgetData} from "./app/slice/baseWidgetSlice";

function Canvas({close}:{close:()=>void}) {

  const generateContainerMap = (baseWidgetData:BaseWidgetSuccessState)=>{
    const ContainerMap: { [key in CanvasComponentKey]: ReactElement } = {
      chat: <Chat baseWidgetData={baseWidgetData} close={close}/>,
    };
    return ContainerMap
  }

  const liveComponent = useAppSelector(selectLiveComponent);
  const baseWidgetData = useAppSelector(selectBaseWidgetData);
  return (
    <Container style={{ height: "inherit", width: "inherit", padding: 0 }}>
      {baseWidgetData.isReady ?  <> {generateContainerMap(baseWidgetData)[liveComponent] } </> :<>Loading</> }
    </Container>
  );
}

export default Canvas;

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import { Avatar } from "@mui/material";
import { ChatMessageType, isRequest, isResponse } from "../app/slice/chatFeedSlice";
import SourceView from "./SourceView";
import MuiMarkdown from "./MuiMarkdown";
import {User} from "../app/services/auth";

type RichChatViewProps = {
  chatMessage: ChatMessageType;
  avatarURL: string;
  user: User;
};
function RichChatView({
  chatMessage,
  avatarURL,
  user,
}: RichChatViewProps) {
  if (isResponse(chatMessage)) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          width: "70%",
          pt: 1,
          pb: 1,
        }}
      >
        <Avatar
          sx={{ width: 20, height: 20 }}
          src={avatarURL}
          style={{ border: "solid rgba(0,0,0,0.15)", borderWidth: "1px" }}
        />
        <Card
          variant="outlined"
          sx={{ ml: 1 }}
          style={{
            background: "rgba(246,246,246,1)",
            border: "none",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: 0,
          }}
        >
          <CardContent
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: "0.9em",
              fontWeight: 400,
              wordWrap: "break-word",
              boxSizing: "content-box",
            }}
          >
            <MuiMarkdown
              markdownText={chatMessage.text}
              markdownCustomCSS={{ fontSize: "0.9em", lineHeight: "1.3" }}
            />
          </CardContent>
          <CardActions
            style={{
              visibility: chatMessage.sources.length > 0 ? "visible" : "hidden",
              borderTop: "0.01rem solid rgba(238,238,238,1)",
              padding: 1,
              width: "inherit",
            }}
          >
            {chatMessage.sources.length > 0 && (
              <SourceView sources={chatMessage.sources} />
            )}
          </CardActions>
        </Card>
      </Box>
    );
  } else if (isRequest(chatMessage)) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          pt: 1,
          pb: 1,
          mr: 1,
        }}
      >
        <Card
          variant="outlined"
          sx={{
            maxWidth: "70%",
            mr: 0.5,
            background: "rgba(50,50,50,1)",
            border: "none",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 10,
          }}
        >
          <CardContent
            style={{
              fontSize: "0.9em",
              padding: 10,
              fontWeight: 300,
              wordWrap: "break-word",
              color: "#FFFFFF",
            }}
          >
            {chatMessage.text}
          </CardContent>
        </Card>
          <Avatar
              sx={{ width: 20, height: 20 }}
              src={user.profileUri}
              style={{ border: "solid rgba(0,0,0,0.15)", borderWidth: "1px" }}
          />
      </Box>
    );
  } else {
    return <></>;
  }
}

export default RichChatView;
